import React from 'react'
import { RichText } from 'prismic-reactjs'

const Image = ({
  image,
  caption,
  image_width
}) => <section className='img psr'>
  <figure className='psr' style={{paddingBottom: image.dimensions.height / image.dimensions.width * 100 + '%'}}>
    <picture>
      {image.Mobile && <source media='(max-width: 800px)' srcSet={image.Mobile.url} />}
      <img className='psa' src={image.url} alt={image.alt} />
    </picture>
  </figure>

  {caption && caption.length > 0 && <p>{RichText.asText(caption)}</p>}
</section>

export default Image
