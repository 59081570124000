import React from 'react'

import Header from './Header'
import Footer from './Footer'
import Text from './Text'

const Landing = () => <section id='landing'>
  <div id='landing-content'  className='px1 py1 c12 x xw'>
    <section className='landing-top mb2 c12'>
      <Header /> 
      <Text />
    </section>

    <section className='landing-bottom c12 xsfe'>
      <Footer />
    </section>
  </div>
</section>

export default Landing
