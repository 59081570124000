import React from 'react'

const clients = [
  'Roy Woods',
  'Artscape',
  '88 Glam',
  'Free',
  'Metro Boomin',
  'Hypebeast',
  'Slamjam',
  'Clot',
  'The Weeknd',
  'Meek Mill',
  'VICE',
  'Loeuvre'
]

const SelectClients = () => <div>
  <p className='mb0-5'>Select Clients</p>
  <div id='clients' className='x xw'>
    {clients.map(c => <div key={c} className='c6'>
      <h2>{c}</h2>
    </div>)}
  </div>
</div>

const Text = () => <div className='ttu'>
  <h1 className='mb1 pt1'>
    Digital creative studio based in Toronto specializing in producing...
  </h1>
  <h2 className='mb2'>
    Art direction, graphic design, web design and development, motion graphics, interactive design, app design and development, digital marketing
  </h2>

  <SelectClients />
</div>

export default Text
