import React from 'react'

import ProjectLink from '../Feed/ProjectLink'

const RelatedPosts = ({ posts = [] }) => 
  <div className='results x px1 xw xjb'>
    {posts.map((block, i) => <ProjectLink 
      key={i} 
      width={'50'}
      {...block} />)}
  </div>

export default RelatedPosts
