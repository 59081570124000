import React from 'react'

import { RichText } from 'prismic-reactjs'

const Video = ({ video_file, caption, video_width }) => {
  return (
    <section className={`single-video my4 width-${video_width}`}>
      <video src={video_file.url} playsInline autoPlay muted loop />

      {caption && caption.length > 0 && <p>{RichText.asText(caption)}</p>}
    </section>
  )
}

export default Video
