import React from 'react'
import { Link } from 'react-router-dom'

import { getPost } from '../../api/prismic'

import Header from './Header'
import RelatedPosts from './RelatedPosts'
import Text from './Text'
import Video from './Video'
import SingleImage from './SingleImage'
import DoubleImage from './DoubleImage'

const contentHandler = (block, i) => {
  console.log(block, 'bbb')
  if (block.slice_type === 'text_block') return <Text key={i} {...block.primary} />
  else if (block.slice_type === 'video_block') return <Video key={i} {...block.primary} />
  else if (block.slice_type === 'single_image_block') return <SingleImage key={i} {...block.primary} />
  else if (block.slice_type === 'double_image_block') return <DoubleImage key={i} {...block.primary} />
  else return false
}


const ProjectLoaded = ({ post }) => {
  return (
    <>
      <Header {...post} />
      <div className='px1'>{post.data.body.map(contentHandler)}</div>
    </>
  )
}


class Project extends React.Component {
  state = {
    loading: true,
    post: false
  }
  
  async componentDidMount () {
    const route = this.props.match.params && this.props.match.params.project

    const res = await getPost(route)
    this.setState({
      loading: false,
      ...res
    })
  }
  
  render () {
    const { content, posts } = this.props
    const { loading, post } = this.state

    return (
      <div id='AR-parent' className={`loading-${loading}`}>

        <Link  className='back2home' to='/'>
          <div />
        </Link>
        <article className='article' 
          style={{ 
          backgroundColor: post && post.data.background_color,
          color: post && post.data.text_color && post.data.text_color,
          }}>

          {loading && <div className='loading' />}
          {post && !loading && <ProjectLoaded {...this.state} {...this.props} />}

          <RelatedPosts posts={posts} />
        </article>
      </div>
    )
  }
}

export default Project
