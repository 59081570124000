import React from 'react'
import { RichText } from 'prismic-reactjs'

const Text = ({ 
  text_spacing,
  text,
  positioning }) => {
    return (
      <section className={`text my4 project-text positioning-${positioning} width-${text_spacing}`}>
        {RichText.render(text)}
      </section>
    )
  }

export default Text
