import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

const Newsletter = () => {
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
    console.log('mxx')
  }, [])
  
  return (
    <div className={`loading-${loading}`} id='nl'>
      <Link id='back2home2' to='/' />
      <aside id='newsletter' className=''>
        <form>
          <input type='email' placeholder='email' />
        </form>
      </aside>
    </div>
  )
}

export default Newsletter
