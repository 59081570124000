import React from 'react'

import Image from '../Image'

const SingleImage = ({
  image,
  caption,
  image_width
}) => <section className={`single-image my4 mxa width-${image_width}`}>
  <Image image={image} caption={caption} />
</section>

export default SingleImage
