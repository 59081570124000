import React from 'react'

import ProjectLink from './ProjectLink'

const Feed = props => {
  const { posts } = props
  
  return (
    <section id='feed' className='py1'>
      {posts.length > 0 && posts.map((p, i) => <ProjectLink key={i} {...p} />)}
    </section>
  )
}

export default Feed
