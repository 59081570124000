import React from 'react'
import { Link } from 'react-router-dom'

import Image from '../Image'

const ProjectLink = props => {
  const { data, uid, width } = props
  
  return (
    <Link className={`project-link ${width ? 'width-' + width : ''}`}  to={`/project/${uid}`}>
      <div className='project px1 mb1'>
        <figure className='mb0-5 psr'>
          {/* <img src={data.cover_photo.url} alt='' /> */}
            <picture>
              {data.cover_photo.Mobile && <source media='(max-width: 800px)' srcSet={data.cover_photo.Mobile.url} />}
              <img className='psa' src={data.cover_photo.url} alt={data.cover_photo.alt} />
            </picture>
        </figure>

        <div className='title ttu mb1'>
          <h2 className='dib vam'>{data.title[0].text}</h2>

          <span className='vam arrow'>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
              <path d="M24.6777 6.3223c0-.5522-.4477-1-1-1h-9c-.5523 0-1 .4478-1 1 0 .5523.4477 1 1 1h8v8c0 .5523.4477 1 1 1s1-.4477 1-1v-9zM6.7071 24.7071L24.3848 7.0294l-1.4142-1.4142L5.2929 23.293l1.4142 1.4142z" fill="#000"/>
            </svg>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default ProjectLink
