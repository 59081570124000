import React from 'react'

import Image from '../Image'

const DoubleImage = ({
  image_left,
  image_right,
  caption_left,
  caption_right,
  image_width
}) => <section className={`double-image x xw mxa xjb xac width-${image_width}`}>
    <div className='left-image immmage'>
      <Image image={image_left} caption={caption_left} />
    </div>
    <div className='right-image immmage'>
      <Image image={image_right} caption={caption_right} />
    </div>
  </section>

export default DoubleImage
