import React from 'react'

import { RichText } from 'prismic-reactjs'

const Header = ({ data, tags }) =>
  <div className='header project-header psr px1 py1'>
    <RichText render={data.alternate_title} />
    <div className='header-bar x xw xjb py1'>
      <div className='year text-medium'><h2>{data.year.length > 0 && RichText.asText(data.year)}</h2></div>
      <div className='tags'>
        {tags && tags.length > 0 && tags.map((tag, i) => <h2 key={i} className='text-medium dib px1 ttu tag'>{tag}</h2>)}
      </div>
    </div>
  </div>

export default Header
