import React from 'react'
import { Link } from 'react-router-dom'

const Logo = () =>
  <div id='five-six-logo'>
      <img src='/assets/56.svg' alt='56 LOGO' />
  </div>

const Nav = () =>
  <nav className='text-small x xw xjb xac ttu'>
    <Link className='pr1 dib' to='/newsletter'><p>Newsletter</p></Link>
    <a className='px1 dib' target='_blank' rel='noopener noreferrer' href='https://instagram.com/56inc'><p>Instagram</p></a>
    <a className='px1 dib' target='_blank' rel='noopener noreferrer' href='https://twitter.com/56_studio'><p>Twitter</p></a>
    <a className='pl1 dib' target='_blank' rel='noopener noreferrer' href='https://shop.56.digital'><p>Shop</p></a>
  </nav>

const Header = () => <header className='c12 x xw xac  xjb'>
    <Logo />
    <Nav />
  </header>

export default Header
