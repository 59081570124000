import Prismic from 'prismic-javascript'

const apiEndpoint = `https://fivesix.cdn.prismic.io/api/v2`

export const getPost = async uid => {
  try {
    const API = await Prismic.api(apiEndpoint)

    const post = await API.getByUID('project', uid)
    // const post = await API.query(Prismic.Predicates.any('document.type', 'post'))

    return {
      post
    }

  } catch (err) {
    return {
      error: err.message
    }
  }
}

export const getPage = async () => {
  try {
    const API = await Prismic.api(apiEndpoint)

    const posts = await API.query(Prismic.Predicates.at('document.type', 'project'), {
      'fetchLinks': [
        'project.title',
        'project.id',
        'project.cover_photo'
      ]
    })
    // const posts = await API.query(Prismic.Predicates.any('document.type', 'post'))

    return {
      posts
    }

  } catch (err) {
    return {
      error: err.message
    }
  }
}
