import React from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom'

import { getPage } from './api/prismic'

import Landing from './components/Landing'
import Feed from './components/Feed'
import RouterHandler from './components/RouterHandler'

class App extends React.Component {
  state = {
    posts: {
      results: []
    },
    loading: true
  }
  
  async componentDidMount () {
    const req = await getPage()
    this.setState({ ...req })
  }
  
  render () {
    const { posts, loading } = this.state
    return (
      <Router>
        <main>
          <Landing />
          <Feed loading={loading} posts={posts.results} />

          <RouterHandler posts={posts.results} />
        </main>
      </Router>
    )
  }
}

export default App