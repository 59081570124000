import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Project from './Project'
import Newsletter from './Newsletter'

const RouterHandler = ({ posts, location }) => {
  return (
    <div>
      <TransitionGroup>
        <CSSTransition
            key={location.pathname}
            timeout={{ enter: 300, exit: 300 }}
            classNames='smooth'
          >
          <Switch location={location}>
            <Route path='/project/:project' render={props => <Project key={props.match.url} {...props} posts={posts} />} />
            <Route path='/newsletter' render={props => <Newsletter />} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default withRouter(RouterHandler)
